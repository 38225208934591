#root {
    height:  100%;
}

#root>div {
    height: 100%;
}
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }