[data-testid ="text-input-outline"]{
    border: 0px solid transparent !important; 
}
[data-testid ="text-input-outlined-label-background"]{
    transform: translateX(0px) translateY(-34px) scale(0.75) !important;
    color: transparent !important;
}
[data-testid ="text-input-outlined-label-active"]{
    transform: translateX(0px) translateY(-34px) scale(0.75) !important;
}
input:-internal-autofill-selected {
    background-color: #00000000 !important;
}
[data-testid ="text-input-outlined-label-background"]{
    top: 23px !important; 
}
[data-testid ="izinputContainer"]{
    width: 100%; 
}
[data-testid ="izinputContainer"]>div:nth-child(2){
    width: 95%; 
}
input:focus {
    outline: none;
}